import React, { useState, useEffect } from 'react';
import './App.css';
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import { Home } from './page/home';
import { qualificationType } from './shared/data-leads';
import { PersonalDetail } from './page/personal-detail';
import { EmployementDetail } from './page/employement-detail';
import { PartnerEmployementDetail } from './page/partner-employeement-detail';
import { OtherIncome } from './page/other-income';
import { CreditHistory } from './page/credit-history';
import { homeFunction } from './function/home-function';
import '../node_modules/react-datetime/css/react-datetime.css'
import { persinalDetailFunction } from './function/personal-detail-function';
import { FunctionEmployeeDetail } from './function/employee-detail';
import { FunctionPartnerEmployeeDetail } from './function/partner-employee-detail';
import { FunctionOtherIncome } from './function/other-income';
import { Thankyou } from './page/thankyou';
const parseQueryString = (queryString: any) => {
  const params: any = {}
  let queries: any = null
  let temp: any = null
  queries = queryString.split("&");
  for (let i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=');
    params[temp[0]] = temp[1];
  }
  return params;
};

type AppProps = {
  firebaseApp: firebase.app.App;
  uid: string
}
const App  = (props: AppProps) => {
  const [page, setpage] = useState("START")
  const [version, setVersion] = useState(0)
  const defLead: qualificationType = {
    Name: "", Purchase: {
      'first Home buyer': "YES",
      'Have you found a property': 'YES'
    },
    "On a probationary period": "NO",
    "Have you ever declared bankruptcy": "NO"
  } //undefined as any
  const [leads, setLeads] = useState(defLead)
  const firebaseApp = props.firebaseApp
  const queryString = (window.location.href.toString().split(window.location.host)[1]).replace("/", "").replace("?", "")
  const querys = parseQueryString(queryString)
  const id = querys["id"]
  const [errorMessage, setErrorMessage] = useState("")
  /* nanti dibuka kalo sudah selesai  */
  useEffect(() => {
    if (id && id.length > 0) {
      firebaseApp.database('https://attain-loans.firebaseio.com/').ref(`facebookLeads/${id}`).once("value").then((dt) => {
        const data: qualificationType = dt.val()
        console.log("data", data)
        if (!data.Purchase!) {
          console.log("Masuk Sini")
          data.Purchase = {
            'first Home buyer': "YES",
            'Have you found a property': 'YES'
          }
        }
        data["On a probationary period"] = "NO"
        data["Have you ever declared bankruptcy"] = "NO"
        setLeads(data)
      })
    }
  }, [id, firebaseApp])

  if (!leads) {
    return <h1>LOADING...</h1>
  }

  const setFormdata = (formData: qualificationType) => {
    console.log("Masuk Pemicu data")
    setLeads(formData)
    setErrorMessage("")
    setVersion(version + 1)
  }
  console.log("leads", leads)
  let header = <img src="/assets/img/logo-white.png" height={45} alt="" />
  let footer = <Row>
    <Col md={6}></Col>
    <Col md={6} style={{ textAlign: 'right' }}><Button className="btn-round btn btn-twitter" onClick={() => homeNext()}>Start <i className="now-ui-icons arrows-1_minimal-right"></i> </Button> </Col>
  </Row>
  let content = <Home formData={leads} setFormData={setFormdata} />
  if (page === 'PERSONALDETAIL') {
    content = <PersonalDetail formData={leads} setFormData={setFormdata} />
    header = <h4>Personal Details</h4>
    footer = <Row>
      <Col xs={6} style={{ textAlign: 'left' }}><Button className="btn-round btn btn-twitter" onClick={() => setpage("START")}><i className="now-ui-icons arrows-1_minimal-left"></i> Back  </Button></Col>
      <Col xs={6} style={{ textAlign: 'right' }}><Button className="btn-round btn btn-twitter" onClick={() => personalNext()}>Next <i className="now-ui-icons arrows-1_minimal-right"></i> </Button> </Col>
    </Row>
  }
  if (page === 'EmploymentDetails') {
    content = <EmployementDetail formData={leads} setFormData={setFormdata} />
    header = <h4>Employment Details</h4>
    footer = <Row>
      <Col sx={6} style={{ textAlign: 'left' }} ><Button className="btn-round btn btn-twitter" onClick={() => setpage("PERSONALDETAIL")}><i className="now-ui-icons arrows-1_minimal-left"></i> Back  </Button></Col>
      <Col sx={6} style={{ textAlign: 'right' }}><Button className="btn-round btn btn-twitter" onClick={() => EmploymentDetailslNext()}>Next <i className="now-ui-icons arrows-1_minimal-right"></i> </Button> </Col>
    </Row>
  }
  if (page === 'PartnerEmploymentDetails') {
    content = <PartnerEmployementDetail formData={leads} setFormData={setFormdata} />
    header = <h4>Partner Employment Details</h4>
    footer = <Row>
      <Col sx={6} style={{ textAlign: 'left' }} ><Button className="btn-round btn btn-twitter" onClick={() => setpage("EmploymentDetails")}><i className="now-ui-icons arrows-1_minimal-left"></i> Back  </Button></Col>
      <Col sx={6} style={{ textAlign: 'right' }}><Button className="btn-round btn btn-twitter" onClick={() => PartnerEmploymentDetailslNext()}>Next <i className="now-ui-icons arrows-1_minimal-right"></i> </Button> </Col>
    </Row>
  }
  if (page === 'OtherIncome') {
    content = <OtherIncome formData={leads} setFormData={setFormdata} />
    header = <h4>Other Income</h4>
    footer = <Row>
      <Col sx={6} style={{ textAlign: 'left' }} ><Button className="btn-round btn btn-twitter" onClick={() => {
        if (leads["Marital Status"] === 'Single') {
          setpage("EmploymentDetails")
        } else {
          setpage("PartnerEmploymentDetails")
        }
      }}><i className="now-ui-icons arrows-1_minimal-left"></i> Back  </Button></Col>
      <Col sx={6} style={{ textAlign: 'right' }}><Button className="btn-round btn btn-twitter" onClick={() => OtherIncomeNext()}>Next <i className="now-ui-icons arrows-1_minimal-right"></i> </Button> </Col>
    </Row>
  }

  if (page === 'CreditHistory') {
    content = <CreditHistory formData={leads} setFormData={setFormdata} />
    header = <h4>Credit History</h4>
    footer = <Row>
      <Col sx={6} style={{ textAlign: 'left' }} ><Button className="btn-round btn btn-twitter" onClick={() => setpage("OtherIncome")}><i className="now-ui-icons arrows-1_minimal-left"></i> Back  </Button></Col>
      <Col sx={6} style={{ textAlign: 'right' }}><Button className="btn-round btn btn-twitter" onClick={() => SubmitData()}>Next <i className="now-ui-icons arrows-1_minimal-right"></i> </Button> </Col>
    </Row>
  }
  if (page === 'Thankyou') {
    content = <Thankyou formData={leads} />
    header = <h4>Thank you</h4>
    footer = <Row>

    </Row>
  }

  const homeNext = () => {
    const checkData = homeFunction(leads)
    console.log("Check Data", checkData)
    if (checkData.error === 0) {
      setpage("PERSONALDETAIL")
      setErrorMessage("")
    } else {
      setErrorMessage(checkData.errorMessage!)
    }

  }

  const personalNext = () => {
    const checkData = persinalDetailFunction(leads)
    console.log("Check Data", checkData)
    if (checkData.error === 0) {
      setpage("EmploymentDetails")
      setErrorMessage("")
    } else {
      setErrorMessage(checkData.errorMessage!)
    }

  }
  const EmploymentDetailslNext = () => {
    const checkData = FunctionEmployeeDetail(leads)
    console.log("Check Data", checkData)
    if (checkData.error === 0) {
      if (leads["Marital Status"] === 'Married/DeFacto') {
        setpage("PartnerEmploymentDetails")
      } else {
        setpage("OtherIncome")
      }
      setErrorMessage("")
    } else {
      setErrorMessage(checkData.errorMessage!)
    }

  }
  const PartnerEmploymentDetailslNext = () => {
    const checkData = FunctionPartnerEmployeeDetail(leads)
    console.log("Check Data", checkData)
    if (checkData.error === 0) {
      setpage("OtherIncome")
      setErrorMessage("")
    } else {
      setErrorMessage(checkData.errorMessage!)
    }
  }
  const OtherIncomeNext = () => {

    const checkData = FunctionOtherIncome(leads)
    console.log("Check Data", checkData)
    if (checkData.error === 0) {
      setpage("CreditHistory")
      setErrorMessage("")
    } else {
      setErrorMessage(checkData.errorMessage!)
    }

  }

  if (errorMessage.length > 0) {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  const SubmitData = async () => {
    const ada = firebaseApp.database('https://attain-loans.firebaseio.com/').ref(`qualification/${props.uid}`).set(leads)
    if (ada) {
      setpage("Thankyou")
    }
  }
  return (
    <div className="App">

      <div className="header">
        <Container> {header} </Container>
      </div>
      <div className="content">
        <Alert color="warning" isOpen={errorMessage.length > 0}>
          <div className="container">

            <strong>Error Input!</strong> {errorMessage}
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => setErrorMessage("")}
            >
              <span aria-hidden="true">
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
        </Alert>
        {content}
      </div>
      <div className="footer">
        <Container>
          {footer}
        </Container>
      </div>

    </div>
  );
}




export default App;
